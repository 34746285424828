import { Modal } from '../Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { FormKitType } from '@organisms';
import { useEffect } from 'react';
import { useKitTypeDetails } from '@hooks';
import { IModalProps } from '@interfaces';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS, updateKitType } from '@api';
import toast from 'react-hot-toast';

interface IModalEditKitTypeProps extends IModalProps {
	editingItemId: string;
}

interface IFormEditKitTypeValues {
	name: string;
	usage: string;
	usageDescription: string;
	requiresPackaging: boolean;
	packagingTypeId: string;
	items: {
		typeId: string;
		quantity: string;
	}[];
}

const DEFAULT_FORM_VALUES = {
	items: [],
	name: '',
	requiresPackaging: false,
	usage: '',
	usageDescription: '',
};

export const ModalEditKitType = ({ editingItemId, isVisible, onCloseModal }: IModalEditKitTypeProps) => {
	const formMethods = useForm<IFormEditKitTypeValues>({
		defaultValues: DEFAULT_FORM_VALUES,
	});
	const {
		formState: { isDirty, dirtyFields },
		handleSubmit: handleSubmitWrapper,
		reset,
	} = formMethods;

	useEffect(() => {
		isVisible && reset();
	}, [isVisible]);

	const { isLoading, isError, data } = useKitTypeDetails(editingItemId);

	useEffect(() => {
		if (isLoading) {
			reset({
				name: 'Loading...',
				usage: 'Loading...',
				usageDescription: 'Loading...',
				items: [],
				requiresPackaging: false,
			});
		}

		if (!isLoading && !isError && !!data) {
			const { kitTypeDetail, name, description, kitUsage } = data;
			reset({
				name,
				usage: kitUsage.id,
				usageDescription: description,
				items: kitTypeDetail
					.filter(detail => !detail.package_container)
					.map(({ quantity, productType }) => ({
						quantity: quantity + '',
						typeId: productType.id,
					})),
				requiresPackaging: kitTypeDetail.some(detail => detail.package_container),
				packagingTypeId: kitTypeDetail.find(detail => detail.package_container)?.productType.id,
			});
		}
	}, [isLoading, isError, data]);

	const queryClient = useQueryClient();
	const { mutate, isPending } = useMutation({
		mutationFn: updateKitType,
		onSuccess: () => {
			onCloseModal();
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.kitsTypes, editingItemId],
			});
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.kitsTypes],
			});
		},
		onError: () => toast.error('No se pudo editar el tipo de kit.'),
	});

	const handleSubmit = (formValues: IFormEditKitTypeValues) => {
		console.log('Kit type id is', editingItemId);
		console.log('Submit with values', formValues);
		// Here I need some sort of diffing based on the dirty fields
		// I can use `dirtyFields` and only send those fields
		console.log(dirtyFields);

		// Cambiar info de la cabecera (done)
		// Cambiar info del detalle, items de seguridad
		// - Agregar items: Creo que solo es agregar otros sin ID (done)
		// {
		//   "itemsType": [
		//     {
		//       "productType": "be1598bd-3611-4561-a874-6b95a529990b",
		//       "quantity": 1
		//     }
		//   ]
		// }
		// - Cambiar tems: Pasar ID y nuevo tipo de item
		// - Actualizar cantidad: Pasar ID y nueva cantidad (done)
		// - Eliminar item: Pasar isActive con false

		// Voy a tratar de enviarlo siempre todo, como si fuera un PUT

		// LLegados a este punto es seguro acceder a la info. De cualquier manera planeo sacarlo de ahi.
		const { kitTypeDetail } = data!;

		const nonContainingSecurityItems: {
			id?: string;
			productType: string;
			quantity: number;
			package_container: boolean;
			isActive: boolean;
		}[] = kitTypeDetail.map(item => {
			const found = formValues.items.find(formItem => formItem.typeId === item.productType.id);
			return {
				id: item.id,
				productType: item.productType.id,
				quantity: found ? +found.quantity : +item.quantity, // From the form
				package_container: false, // Todos los de aquí son no contenedores
				isActive: true, // For now
			};
		});

		// If the number of submitted items is less than the original, we should set the missing item isActive property to false
		console.log(kitTypeDetail);
		const originalNonContainingItems = kitTypeDetail.filter(detail => !detail.package_container);
		if (originalNonContainingItems.length !== formValues.items.length) {
			console.log('Deleting an item');
			// Conseguir los items que hayan sido removidos
			const removedItems = originalNonContainingItems.filter(originalDetail => {
				return !formValues.items.find(
					submittedDetail => submittedDetail.typeId === originalDetail.productType.id
				);
			});
			console.log('Deleted items', removedItems);
			nonContainingSecurityItems.forEach(item => {
				const found = removedItems.find(removedItem => removedItem.productType.id === item.productType);
				if (found) {
					item.isActive = false;
				}
			});
		}

		// We add items
		formValues.items.forEach(formDetailItem => {
			const isAlreadyAdded = !!nonContainingSecurityItems.find(
				item => item.productType === formDetailItem.typeId
			);
			if (!isAlreadyAdded) {
				nonContainingSecurityItems.push({
					// No id means the item will be added
					productType: formDetailItem.typeId,
					quantity: +formDetailItem.quantity, // From the form
					package_container: false, // Todos los de aquí son no contenedores
					isActive: true, // For now
				});
			}
		});

		const containerFound = kitTypeDetail.find(
			originalItem => originalItem.productType.id === formValues.packagingTypeId
		);
		const containerId = containerFound ? { id: containerFound.id } : {};
		const containingSecurityItems = formValues.packagingTypeId
			? [
					{
						...containerId, // This should be set dinamically
						productType: formValues.packagingTypeId,
						quantity: 1,
						package_container: true,
						isActive: formValues.requiresPackaging,
					},
				]
			: [];

		const remoteKitType = {
			name: formValues.name,
			description: formValues.usageDescription,
			kitUsage: formValues.usage,
			itemsType: [...nonContainingSecurityItems, ...containingSecurityItems],
		};

		mutate({
			id: editingItemId,

			...remoteKitType,
		});
	};

	return (
		<Modal
			className='p-10'
			title='Edición del contenido del kit'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-5/6 mb-4'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
				dolore magna aliqua.
			</p>
			<FormProvider {...formMethods}>
				<FormKitType
					isLoading={isPending}
					onCancel={onCloseModal}
					onSubmit={handleSubmitWrapper(handleSubmit)}
					canSave={isDirty}
				/>
			</FormProvider>
		</Modal>
	);
};
