import { IcArrowLeft } from '@atoms';
import { MouseEventHandler } from 'react';

interface IButtonGoBackProps {
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const ButtonGoBack = ({ onClick }: IButtonGoBackProps) => {
	return (
		<button onClick={onClick} className='self-start inline-flex items-center gap-2 transition-transform group'>
			<IcArrowLeft className='w-4 stroke-2 stroke-bummock-midnight_blue group-hover:-translate-x-1 transition-transform' />
			<span className='font-semibold'>Back</span>
		</button>
	);
};
