import type { IFormLabelProps } from './FormLabel.interfaces';

export const FormLabel = ({ id, label, onError, required, disabled, onClick }: IFormLabelProps) => {
	return (
		<label
			htmlFor={id}
			className={`font-semibold text-2xs leading-none ${onError ? 'text-error-500' : ''} ${disabled ? 'text-bummock-disabled_grey_2' : 'text-bummock-midnight_blue'}`}
			onClick={onClick}
		>
			{label}
			{required ? (
				<span className='text-error-500' aria-label='required' aria-disabled={disabled}>
					*
				</span>
			) : null}
		</label>
	);
};
