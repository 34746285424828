import { ReactNode, useRef } from 'react';
import { IcClose } from '@atoms';
import styles from './Modal.module.sass';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { createPortal } from 'react-dom';

const FADE_DURATION = 0.4;

interface IModalProps {
	className?: string;
	title?: string;
	children: ReactNode;
	isOpen?: boolean;
	onClose: () => void;
	closeButton?: boolean;
	closeOnClickingOutside?: boolean;
}

export const Modal = ({
	title,
	isOpen,
	onClose,
	children,
	closeButton = true,
	closeOnClickingOutside = false,
	className = '',
}: IModalProps) => {
	const modalContainerRef = useRef<HTMLDivElement>(null);

	useGSAP(() => {
		if (isOpen) {
			gsap.timeline()
				.to(modalContainerRef.current, {
					display: 'flex',
					duration: 0,
				})
				.to(modalContainerRef.current, {
					opacity: 1,
					duration: FADE_DURATION,
				});
		} else {
			gsap.timeline()
				.to(modalContainerRef.current, {
					opacity: 0,
					duration: FADE_DURATION,
				})
				.to(modalContainerRef.current, {
					display: 'none',
					duration: 0,
				});
		}
	}, [isOpen]);

	return (
		<>
			{createPortal(
				<div ref={modalContainerRef} className={`${styles['modal']}`}>
					<div
						{...(closeOnClickingOutside ? { onClick: onClose } : {})}
						className={`${styles['modal__backdrop']} ${closeOnClickingOutside ? 'cursor-pointer' : ''}`}
					></div>
					<div className={`${styles['modal__content']} ${className}`}>
						{!!title && (
							<header>
								<h2 className={styles['modal__content__title']}>{title}</h2>
								{closeButton && (
									<button
										onClick={onClose}
										className='flex justify-center items-center size-7 transition-colors stroke-bummock-disabled_grey_2 hover:stroke-bummock-midnight_blue'
									>
										<IcClose className='w-[0.875rem] stroke-2' />
									</button>
								)}
							</header>
						)}
						{children}
					</div>
				</div>,
				document.getElementById('root')!
			)}
		</>
	);
};
