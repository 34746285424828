import styles from './BadgeIcon.module.sass';
import { IBadgeIconProps } from './BadgeIcon.interfaces';

export const BadgeIcon = ({ label, icon }: IBadgeIconProps) => {
	return (
		<span className={styles['badge-icon']}>
			{icon}
			{label}
		</span>
	);
};
