import { useEffect } from 'react';
import { Header, flexRender } from '@tanstack/react-table';
import type { Table } from '@tanstack/react-table';
import { IcArrowNarrowUp, IcSwitchVertical } from '@atoms';
import { Pagination, TableSkeleton } from '@organisms';
import { IBaseTableProps } from './BaseTable.interfaces';
import { IUseTableQueryResult } from '@hooks';
import { Searchbar } from '@molecules';

export function BaseTable<T>({ className = '', tableQueryResult }: IBaseTableProps<T>) {
	const { table, queryResult } = tableQueryResult;
	const { isLoading: isTableDataLoading, searchTerm } = queryResult;
	const { setPagination } = table;

	// to reset page index to first page
	useEffect(() => {
		if (setPagination) {
			setPagination(pagination => ({
				pageIndex: 0,
				pageSize: pagination.pageSize,
			}));
		}
	}, [setPagination, searchTerm]);

	if (isTableDataLoading) return <TableSkeleton />;

	return (
		<div className='grow'>
			<DumbTable className={`w-full ${className}`} table={table!} />
		</div>
	);
}

interface ITablePaginationProps<TData> {
	tableQueryResult: IUseTableQueryResult<TData>;
}
export function TablePagination<TData>({ tableQueryResult }: ITablePaginationProps<TData>) {
	const { table } = tableQueryResult;
	const rowsShownCount = table.getRowCount();

	if (rowsShownCount === 0) return null;

	return (
		<Pagination
			currentPageIndex={table.getState().pagination.pageIndex}
			itemsPerPage={table.getState().pagination.pageSize}
			numberOfItems={table.getRowCount()}
			onChangePage={page => table.setPageIndex(page)}
		/>
	);
}

interface ITableProps<TData> {
	className: string;
	table: Table<TData>;
}
function DumbTable<TData>({ table, className = '' }: ITableProps<TData>) {
	const sortToggler = (header: Header<TData, unknown>) => {
		if (header.column.getCanSort()) {
			header.column.toggleSorting(undefined, true);
		}
	};

	return (
		<table className={className}>
			<thead>
				{table.getHeaderGroups().map(header => (
					<tr key={header.id}>
						{header.headers.map(header => (
							<th key={header.id}>
								{header.isPlaceholder ? null : (
									<div
										onClick={() => sortToggler(header)}
										className={`${header.column.getCanSort() ? 'hover:cursor-pointer' : ''} flex items-center ${header.column.columnDef.meta?.alignment === 'center' ? 'justify-center' : header.column.columnDef.meta?.alignment === 'right' ? 'justify-end' : 'justify-start'} gap-4`}
									>
										{flexRender(header.column.columnDef.header, header.getContext())}
										{header.column.getCanSort() && (
											<div className='w-4'>
												{header.column.getIsSorted() === 'asc' ? (
													<IcArrowNarrowUp className='stroke-bummock-midnight_blue stroke-[2] w-[12px]' />
												) : header.column.getIsSorted() === 'desc' ? (
													<IcArrowNarrowUp className='rotate-180 stroke-bummock-midnight_blue stroke-[2] w-[12px]' />
												) : (
													<IcSwitchVertical className='stroke-[1.5] w-4' />
												)}
											</div>
										)}
									</div>
								)}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.length === 0 ? (
					<tr>
						<td colSpan={100}>No data found</td>
					</tr>
				) : (
					<>
						{table.getRowModel().rows.map(row => (
							<tr key={row.id}>
								{row.getVisibleCells().map(cell => (
									<td
										className={
											cell.column.columnDef.meta?.alignment === 'center'
												? 'text-center'
												: cell.column.columnDef.meta?.alignment === 'right'
													? 'text-right'
													: 'text-left'
										}
										key={cell.id}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						))}
					</>
				)}
			</tbody>
		</table>
	);
}

interface ITableSearchbarProps<TData> {
	className?: string;
	tableQueryResult: IUseTableQueryResult<TData>;
}
export function TableSearchbar<T>({ tableQueryResult, className = '' }: ITableSearchbarProps<T>) {
	const { queryResult } = tableQueryResult;
	return (
		<Searchbar
			className={className}
			searchDelayMs={350}
			onSearch={searchTerm => queryResult.setSearchTerm(searchTerm)}
		/>
	);
}
