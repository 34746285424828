import { IPaginatedResource } from '@interfaces';
import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	type TableOptions,
	PaginationState,
	RowData,
	SortingState,
	Table,
	TableMeta,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { ITEMS_PER_PAGE } from '@utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type IUsePaginatedDataResult<T> = UseQueryResult<T> & {
	pagination: PaginationState;
	setPagination: Dispatch<SetStateAction<PaginationState>>;
	sorting: SortingState;
	setSorting: Dispatch<SetStateAction<SortingState>>;
	searchTerm: string;
	setSearchTerm: Dispatch<SetStateAction<string>>;
};

interface IUsePaginatedDataProps<T> {
	itemsPerPage?: number;
	initialPage?: number;
	queryKey: string[];
	fetcherFn: (params: { page: number; sorting: SortingState; searchTerm: string }) => Promise<T>;
}

// This makes use of React Query's caching & state management capabilities applying them to paginated
// data retrieval & prefetching.
const usePaginatedData = <T extends IPaginatedResource>({
	initialPage = 0,
	queryKey,
	fetcherFn,
}: IUsePaginatedDataProps<T>): IUsePaginatedDataResult<T> => {
	const queryClient = useQueryClient();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [sorting, setSorting] = useState<SortingState>([]);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: initialPage, //initial page index
		pageSize: ITEMS_PER_PAGE, //default page size
	});

	const queryResult = useQuery<T>({
		queryKey: [...queryKey, pagination.pageIndex, sorting, searchTerm],
		queryFn: () => fetcherFn({ page: pagination.pageIndex, sorting, searchTerm }),
	});

	// Prefetching
	useEffect(() => {
		if (!queryResult.data) return;
		const { totalPages } = queryResult.data.meta;

		// Prefetchs next page
		if (pagination.pageIndex + 1 < totalPages) {
			const nextPage = pagination.pageIndex + 1;
			queryClient.prefetchQuery({
				queryKey: [...queryKey, nextPage, sorting, searchTerm],
				queryFn: () => fetcherFn({ page: nextPage, sorting, searchTerm }),
			});
		}

		// Prefetchs previous page
		if (pagination.pageIndex > 0) {
			const prevPage = pagination.pageIndex - 1;
			queryClient.prefetchQuery({
				queryKey: [...queryKey, prevPage, sorting, searchTerm],
				queryFn: () => fetcherFn({ page: prevPage, sorting, searchTerm }),
			});
		}
	}, [pagination.pageIndex, queryClient, queryResult.data]);

	return { ...queryResult, pagination, setPagination, sorting, setSorting, searchTerm, setSearchTerm };
};

type TPaginatedServerResponse<T> = {
	data: T[];
} & IPaginatedResource;

interface IUseTableQueryProps<T extends RowData> {
	// Nasty workaround
	tableColumns: TableOptions<T>['columns'][number][];
	queryKey: string[];
	fetcherFn: (params: {
		page: number;
		sorting: SortingState;
		searchTerm: string;
	}) => Promise<TPaginatedServerResponse<T>>;
	meta?: TableMeta<T>;
}

export interface IUseTableQueryResult<T> {
	queryResult: IUsePaginatedDataResult<TPaginatedServerResponse<T>>;
	table: Table<T>;
}

// This merges React Table & React Query providing a server-side table.
export const useTableQuery = <T>({
	queryKey,
	fetcherFn,
	tableColumns,
	meta,
}: IUseTableQueryProps<T>): IUseTableQueryResult<T> => {
	const tableQueryResult = usePaginatedData({
		queryKey,
		fetcherFn,
	});

	const { data: tableData, setSorting, setPagination, sorting, pagination } = tableQueryResult;

	const table = useReactTable({
		data: tableData?.data || [],
		columns: tableColumns,
		getCoreRowModel: getCoreRowModel(),

		// sort config
		onSortingChange: setSorting,
		enableMultiSort: false,
		manualSorting: true,
		sortDescFirst: true,

		// filter config
		getFilteredRowModel: getFilteredRowModel(),
		manualFiltering: true,

		// pagination config
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,
		rowCount: tableData?.meta.totalItems,
		manualPagination: true,

		state: {
			sorting,
			pagination,
		},

		meta,
	});

	return {
		queryResult: tableQueryResult,
		table,
	};
};
