import { useEffect, useRef, useState } from 'react';
import styles from './CardDropDown.module.sass';

import { IcChevronDown, InputCheckbox } from '@atoms';

import type { IDropDownCardProps } from './CardDropDown.interfaces.ts';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

export const CardDropDown = ({
	title,
	description,
	options,
	isOpen = false,
	onClick,
	onCheckItem = () => {},
}: IDropDownCardProps) => {
	const [isCardOpen, setIsCardOpen] = useState<boolean>(isOpen);
	const elementRef = useRef<HTMLUListElement>(null);

	useEffect(() => setIsCardOpen(isOpen), [isOpen]);

	useGSAP(() => {
		if (!elementRef.current) return;
		gsap.to(elementRef.current, { height: isCardOpen ? 'auto' : '0', duration: 0.3 });
	}, [isCardOpen]);

	const handleClickCard = () => {
		setIsCardOpen(!isCardOpen);
		onClick && onClick(!isCardOpen);
	};

	return (
		<li onClick={handleClickCard} className={`${styles['card-drop-down']}`}>
			<div className={styles['card-drop-down__description-container']}>
				<header>
					<h2 className={styles['card-drop-down__description-container__title']}>{title}</h2>
					<IcChevronDown />
				</header>
				<p>{description}</p>
			</div>
			<ul
				ref={elementRef}
				onClick={e => e.stopPropagation()}
				className={`${styles['card-drop-down__check-list']} h-0`}
			>
				{options.map(option => (
					<li key={option.id} className={styles['card-drop-down__check-list__item']}>
						<InputCheckbox
							onChange={e => onCheckItem(option.id, e.target.checked)}
							checked={option.isSelected}
							className='shrink-0'
						/>
						<aside>
							<h3 className={styles['card-drop-down__check-list__item__title']}>{option.title}</h3>
							<p>{option.description}</p>
						</aside>
					</li>
				))}
			</ul>
		</li>
	);
};
