import { IcCheckCircle } from '@atoms';
import { IInputValidationCriteriaProps } from './InputValidationCriteria.interfaces';

export const InputValidationCriteria = ({ criterias }: IInputValidationCriteriaProps) => {
	return (
		<ul className='flex flex-col gap-y-3'>
			{criterias.map(({ callback, message }) => (
				<li key={message} className='flex gap-x-2 font-normal text-sm text-bummock-midnight_blue'>
					<IcCheckCircle
						className={`shrink-0 w-5 ${callback() ? 'fill-success-500' : 'fill-bummock-disabled_grey_2'}`}
					/>
					<span>{message}</span>
				</li>
			))}
		</ul>
	);
};
