import { FormInput, FormInputSwitch, FormSearchableDropdown } from '@molecules';
import { Modal } from '../Modal';
import { Button, DashedContainer, IcPlus, IcTrash } from '@atoms';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useAppSelector, useKitsUsages, useProductsTypes } from '@hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createKitType } from '@api';
import { IModalProps } from '@interfaces';

interface IFormNewKitTypeValues {
	name: string;
	usage: string;
	usageDescription: string;
	requiresPackaging: boolean;
	packagingTypeId: string;
	items: {
		typeId: string;
		quantity: string;
	}[];
}

const INITIAL_LENGTH = 1;

export const ModalNewKitType = ({ isVisible = true, onCloseModal = () => {} }: IModalProps) => {
	const { companies } = useAppSelector(state => state.userProfile);

	const {
		formState: { errors, isValid },
		register,
		control,
		handleSubmit: handleSubmitWrapper,
		reset,
		watch,
	} = useForm<IFormNewKitTypeValues>({
		defaultValues: {
			name: '',
			usage: '',
			usageDescription: '',
			requiresPackaging: false,
			items: Array(INITIAL_LENGTH).fill({
				typeId: '',
				quantity: '',
			}),
		},
	});

	const { fields, append, remove } = useFieldArray({
		name: 'items',
		control,
	});

	useEffect(() => {
		if (isVisible) reset();
	}, [isVisible]);

	const { data: productsTypesResponse } = useProductsTypes();
	const { data: kitsUsagesResponse } = useKitsUsages();

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: createKitType,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['kits-types'] });
			onCloseModal();
		},
	});

	const handleSubmit = ({ name, usage, items, usageDescription, packagingTypeId }: IFormNewKitTypeValues) => {
		mutation.mutate({
			name,
			description: usageDescription,
			kitUsage: usage,
			company: companies[0].id,
			itemsType: [
				...items.map(({ typeId, quantity }) => ({
					productType: typeId,
					quantity: +quantity,
					package_container: false,
				})),
				...(packagingTypeId
					? [
							{
								productType: packagingTypeId,
								quantity: 1,
								package_container: true,
							},
						]
					: []),
			],
		});
	};

	return (
		<Modal
			className='p-10 min-w-[50rem]'
			title='Creacion del contenido del kit'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-5/6 mb-4'>
				Crea tus kits personalizados con los productos previamente añadidos en el inventario.
			</p>
			<form onSubmit={handleSubmitWrapper(handleSubmit)} autoComplete='off'>
				<div className='flex gap-8 mb-8'>
					<FormInput
						className='basis-0 grow'
						register={register('name', { required: { value: true, message: 'This field is required' } })}
						required={true}
						errorMessage={errors.name?.message}
						onError={!!errors.name}
						label='Nombre del Kit'
						placeholder='Name'
					/>
					<Controller
						control={control}
						name='usage'
						rules={{ required: { value: true, message: 'This field is required' } }}
						render={({ field: { ref, ...rest }, fieldState: { error } }) => (
							<FormSearchableDropdown
								includeEmptyOption={true}
								className='basis-0 grow'
								label='Uso de Kit'
								required={true}
								options={
									kitsUsagesResponse?.data.map(productType => ({
										id: productType.id,
										value: productType.name,
									})) ?? []
								}
								onError={!!error?.message}
								errorMessage={error?.message}
								{...rest}
							/>
						)}
					/>
				</div>

				<FormInput
					className='mb-8'
					register={register('usageDescription', {
						required: { value: true, message: 'This field is required' },
					})}
					required={true}
					errorMessage={errors.name?.message}
					onError={!!errors.name}
					label='Descripción de Uso'
					placeholder='Description'
				/>

				{fields.map((_, index) => {
					return (
						<div className='flex gap-8 mt-4' key={index}>
							<Controller
								control={control}
								name={`items.${index}.typeId`}
								rules={{ required: { value: true, message: 'This field is required' } }}
								render={({ field: { ref, ...rest }, fieldState: { error } }) => (
									<FormSearchableDropdown
										includeEmptyOption={true}
										className='grow'
										label={`Producto ${index + 1}`}
										required={true}
										options={
											productsTypesResponse?.data
												.map(productType => ({
													id: productType.id,
													value: productType.name,
												}))
												// TODO: This is buggy, fix in FormKitType and use that component here
												// filters out the items that are already selected in the other inputs
												.filter(productType => {
													const alreadySelectedItems = Array(fields.length)
														.fill(0)
														.map((_, otherIndex) =>
															index === otherIndex
																? ''
																: watch(`items.${otherIndex}.typeId`)
														)
														.filter(item => item.length > 0)
														// Creates a hash table to perform a fast lookup
														.reduce(
															(prev, curr) => {
																prev[curr] = true;
																return prev;
															},
															{} as Record<string, boolean>
														);

													return !alreadySelectedItems[productType.id];
												}) ?? []
										}
										onError={!!error?.message}
										errorMessage={error?.message}
										{...rest}
									/>
								)}
							/>
							<FormInput
								register={register(`items.${index}.quantity`, {
									required: { value: true, message: 'Required' },
								})}
								required={true}
								errorMessage={errors?.items?.[index]?.quantity?.message}
								onError={!!errors?.items?.[index]?.quantity?.message}
								className='basis-[5.9375rem]'
								label='Cantidad'
								placeholder='0'
							/>

							<button
								className={`self-center mt-[1.3rem] stroke-bummock-disabled_grey_2 ${fields.length > INITIAL_LENGTH ? 'hover:stroke-bummock-midnight_blue' : 'cursor-not-allowed'}`}
								disabled={fields.length <= INITIAL_LENGTH}
								tabIndex={-1}
								type='button'
								onClick={() => remove(index)}
							>
								<IcTrash
									className={`w-[1.25rem] m-1 stroke-2 stroke-inherit transition-colors duration-200`}
								/>
							</button>
						</div>
					);
				})}

				<DashedContainer
					onClick={() => {
						append({
							typeId: '',
							quantity: '',
						});
					}}
					className='cursor-pointer mt-4'
				>
					<span className='flex gap-2 text-sm'>
						<IcPlus className='stroke-2 stroke-bummock-midnight_blue w-4' />
						Agregar Item
					</span>
				</DashedContainer>

				<FormInputSwitch
					variant='secondary'
					label='Requiere Packaging para almacenar los productos.'
					register={register('requiresPackaging')}
					className='mt-8'
				/>
				{watch('requiresPackaging') && (
					<Controller
						control={control}
						name='packagingTypeId'
						rules={{ required: { value: true, message: 'This field is required' } }}
						defaultValue=''
						render={({ field: { ref, ...rest }, fieldState: { error } }) => (
							<FormSearchableDropdown
								includeEmptyOption={true}
								label='Packaging'
								className='mt-8'
								options={
									productsTypesResponse?.data
										.map(productType => ({
											id: productType.id,
											value: productType.name,
										}))
										.filter(productType => {
											const alreadySelectedItems = Array(fields.length)
												.fill(0)
												.map((_, index) => watch(`items.${index}.typeId`))
												.filter(item => item.length > 0)
												// Creates a hash table to perform a fast lookup
												.reduce(
													(prev, curr) => {
														prev[curr] = true;
														return prev;
													},
													{} as Record<string, boolean>
												);
											return !alreadySelectedItems[productType.id];
										}) ?? []
								}
								onError={!!error?.message}
								errorMessage={error?.message}
								{...rest}
							/>
						)}
					/>
				)}

				<div className='flex gap-4 mt-8'>
					<Button onClick={onCloseModal} type='button' variant='secondary' className='w-[9.375rem]'>
						Cancelar
					</Button>
					<Button isLoading={mutation.isPending} disabled={!isValid} className='w-[9.375rem]'>
						Guardar
					</Button>
				</div>
			</form>
		</Modal>
	);
};
