import { useEffect, useRef, type FC } from 'react';

import type { IButtonProps } from './Button.interfaces.ts';

import styles from './Button.module.sass';
import gsap from 'gsap';
import { IcSpinner } from '../Icons/IcSpinner.tsx';

// Manages gsap gradient transitions
const useButtonAnimation = ({ variant, disabled }: Pick<IButtonProps, 'variant' | 'disabled'>) => {
	const GRADIENT_FROM = 'linear-gradient(rgba(0,60,103,1) 0%, rgba(3,97,163,1) 100%)';
	const GRADIENT_TO = 'linear-gradient(rgba(3,97,163,1) 0%, rgba(0,60,103,1) 100%)';
	const GRADIENT_DISABLED = 'linear-gradient(rgba(255, 255, 255,1) 0%, rgba(255,255,255,1) 100%)';

	const isFirstRender = useRef(true);
	const buttonRef = useRef<HTMLButtonElement>(null);

	const onMouseEnter = () => {
		if (variant !== 'primary') return;
		gsap.timeline()
			.to(buttonRef.current!, { background: GRADIENT_FROM, ease: 'none', duration: 0 })
			.to(buttonRef.current!, {
				background: GRADIENT_TO,
				ease: 'none',
				duration: 0.3,
			});
	};

	const onMouseLeave = () => {
		if (variant !== 'primary') return;
		gsap.to(buttonRef.current!, {
			background: GRADIENT_FROM,
			ease: 'none',
			duration: 0.3,
		});
	};

	useEffect(() => {
		if (!buttonRef.current) return;
		if (isFirstRender.current && variant === 'primary') return;

		if (variant !== 'primary') return;
		gsap.to(buttonRef.current, {
			background: disabled ? GRADIENT_DISABLED : GRADIENT_FROM,
			duration: 0.3,
		});
	}, [disabled]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		}
		return () => {
			isFirstRender.current = true;
		};
	}, []);

	return { buttonRef, animationHandlers: { onMouseEnter, onMouseLeave } };
};

export const Button: FC<IButtonProps> = ({
	children,
	className,
	disabled,
	isLoading,
	mRef,
	variant = 'primary',
	...rest
}) => {
	const { buttonRef, animationHandlers } = useButtonAnimation({ disabled, variant });

	if (isLoading) {
		return (
			<button
				className={`${styles.button} ${styles['button--loading']} ${className} relative`}
				aria-disabled={disabled}
				data-variant={variant}
				disabled
				{...rest}
			>
				<div className='opacity-0 flex items-center justify-center gap-2'>{children}</div>
				<div className='absolute left-1/2 -translate-x-1/2'>
					<IcSpinner className='w-[1.375rem] stroke-2 stroke-bummock-off_white' />
				</div>
			</button>
		);
	}

	if (disabled) {
		return (
			<button
				ref={buttonRef}
				className={`${styles.button} ${className}`}
				aria-disabled={disabled}
				data-variant={variant}
				disabled
				{...rest}
			>
				{children}
			</button>
		);
	}

	return (
		<button
			{...animationHandlers}
			ref={buttonRef}
			className={`${styles.button} ${className}`}
			data-variant={variant}
			{...rest}
		>
			{children}
		</button>
	);
};
