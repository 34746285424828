import { ReactNode } from 'react';
import styles from './DashedContainer.module.sass';

interface IDashedContainerProps {
	className?: string;
	children: ReactNode;
	onClick?: () => void;
}

export const DashedContainer = ({ children, className = '', onClick = () => {} }: IDashedContainerProps) => {
	return (
		<div className={`${styles['dashed-container']} ${className}`} onClick={onClick}>
			{children}
		</div>
	);
};
