import { IcMinus } from '../Icons/IcMinus';
import { IcPlus } from '../Icons/IcPlus';

interface ICounterProps {
	id: string;
	disabled?: boolean;
	availableStock: number;
	count: string;
	onCount: (value: string) => void;
}

// TODO: Refactor this to allow for typing
export const Counter = ({ id, count, onCount, availableStock, disabled }: ICounterProps) => {
	// This should receive a string, if when parsing it ends up being NaN, return 0
	const clampCount = (value: number) => {
		if (value <= 0) return '0';
		if (value >= availableStock) return availableStock.toString();
		return value.toString();
	};

	return (
		<div className='flex justify-center gap-2'>
			<button
				disabled={disabled}
				className='disabled:stroke-gray-200 stroke-bummock-midnight_blue'
				tabIndex={-1}
				onClick={() => onCount(clampCount(+count - 1))}
			>
				<IcMinus className='size-4 stroke-inherit' />
			</button>
			<input
				disabled={disabled}
				id={id}
				placeholder='0'
				autoComplete='off'
				className={`w-[60px] text-center disabled:text-gray-200`}
				value={count}
				onChange={e => {
					const inputValue = e.target.value;
					const isNumber = !Number.isNaN(Number.parseInt(inputValue));
					if (isNumber) {
						onCount(clampCount(+inputValue));
					} else {
						onCount(inputValue);
					}
				}}
			/>
			<button
				disabled={disabled}
				className='disabled:stroke-gray-200 stroke-bummock-midnight_blue'
				tabIndex={-1}
				onClick={() => onCount(clampCount(+count + 1))}
			>
				<IcPlus className='size-4 stroke-inherit stroke-2' />
			</button>
		</div>
	);
};
