import { findAllProductsTypes, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useProductsTypes = () => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.productsTypes],
		queryFn: () => findAllProductsTypes({ page: 0, limit: 100000, sorting: [], searchTerm: '' }),
	});

	return queryResult;
};
