import { IBadgeProps } from './Badge.interfaces';
import styles from './Badge.module.sass';

export const Badge = ({ variant = 'success', label }: IBadgeProps) => {
	return (
		<span className={styles['badge']} data-variant={variant}>
			{label}
		</span>
	);
};
